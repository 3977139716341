
import ApplicationLogo from "@/Components/ApplicationLogo";
import Modal from "@/Components/Modal";
import { Button } from "@/Components/ui/button";
import { Input } from "@/Components/ui/input";
import { setComingSoon, setLoader } from "@/Hooks/Functions";
import { Icon } from "@iconify/react";
import { useForm, usePage } from "@inertiajs/react";
import { state } from "@state/store";
import { toast } from "sonner";
import { useSnapshot } from "valtio";

export default function ComingSoonModal() {
    const { auth } = usePage().props;
    const snap = useSnapshot(state);

    const { data, setData, post, reset } = useForm({
        email: auth?.user?.email || '',
    });

    const submit = () => {
        post(route('newsletter.subscribe'), {
            onStart: () => setLoader(true, 'Subscribing...'),
            onSuccess: () => {
                toast.success('Subscribed successfully');
                setComingSoon(false);
            },
            onError: (error) => toast.error(error.email),
            onFinish: () => { reset(); setLoader(false); }
        });
    }

    return <Modal title="Coming Soon" noHeader className="max-w-7xl overflow-hidden w-full" show={snap.comingSoon} onClose={() => (state.comingSoon = false)}>
        <div className="relative h-[80vh] w-full flex items-center bg-predesigns-3 justify-center bg-cover bg-center text-center px-5" >
            <div className="absolute top-0 right-0 bottom-0 left-0 bg-gray-900 opacity-75"></div>

            <div className="z-50 flex flex-col justify-center text-white w-full h-screen">
                <ApplicationLogo white={true} className="h-[10vh] aspect-auto w-fit mx-auto" />
                <h1 className="md:text-5xl text-xl">We are <b>Almost</b> there!</h1>
                <p>We are working on it. Stay tuned for something amazing!!!</p>

                <div className="sm:mt-40">
                    <form className="w-full max-w-xl mx-auto">
                        <label className="block mb-2 text-sm font-medium text-white">Subscribe to our newsletter</label>
                        <div className="flex gap-4 items-center  py-2">
                            <Input value={data.email} onChange={(e) => setData('email', e.target.value)} placeholder="Enter your email address" />
                            <Button onClick={submit} variant="theme">Submit</Button>
                        </div>
                    </form>
                </div>

                <div className="mt-6 flex text-white gap-4 mx-auto">
                    <Button variant="link" className="text-xl">
                        <Icon icon="logos:facebook" />
                    </Button>
                    <Button variant="link" className="text-xl">
                        <Icon icon="fa6-brands:x-twitter" className="text-white" />
                    </Button>
                    <Button variant="link" className="text-xl">
                        <Icon icon="skill-icons:instagram" />
                    </Button>
                    <Button variant="link" className="text-xl">
                        <Icon icon="logos:youtube-icon" />
                    </Button>
                </div>

            </div>
        </div>
    </Modal>
}
